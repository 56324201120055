import Public from '@/components/public/Public';
import Home from '@/components/public/Home';
import Register from '@/components/public/registration/Register.COM.AU';
import AgencyRegistration from '@/components/public/registration/AgencyRegistration.COM.AU';
import About from '@/components/public/About';
import Contact from '@/components/public/Contact';
//import Suppliers from '@/components/public/Suppliers';
import Groups from '@/components/public/Groups';

import Deals from '@/components/public/Deals';
//import DealsAustralianHolidays from '@/components/public/DealsAustralianHolidays';
//import DealsHalfPriceFlights from '@/components/public/DealsHalfPriceFlights';
import DealsCoach from '@/components/public/DealsCoach';
import DealsCoachTours from '@/components/public/DealsCoachTours';
import DealsRail from '@/components/public/DealsRail';
import DealsCityBreaks from '@/components/public/DealsNZCityBreaks';
import DealsChristchurch from '@/components/public/DealsChristchurch';
import DealsHurunui from '@/components/public/DealsHurunui';
//import DealsWaikato from '@/components/public/DealsWaikato';
//import DealsQueenstown from '@/components/public/DealsQueenstown';
import DealsAllTime from '@/components/public/DealsAllTimeNZHolidays';
import DealsAustraliaBucketList from '@/components/public/DealsAustraliaBucketList';
import DealsAustraliaNatureCoast from '@/components/public/DealsAustraliaNatureCoast';
//import DealsSignatureAustralia from '@/components/public/DealsSignatureAustralia';
import DealsPacific from '@/components/public/DealsPacific';
import DealsRotorua from '@/components/public/DealsRotorua';
import DealsNZBook from '@/components/public/DealsNZBook';
import DealsGardens from '@/components/public/DealsGardensOfNZ';
import DealsBayOfPlenty from '@/components/public/DealsBayOfPlenty';
import DealsLordHoweNorfolk from '@/components/public/DealsLordHoweNorfolk';
import DealsWalkingAU from '@/components/public/DealsAustraliaWalking.vue';
import DealsIconicNZ from '@/components/public/DealsIconicNZ.vue';
import DealsNZHighways from '@/components/public/DealsNZHighways';

import Login from '@/components/login';
import RecoverPassword from '@/components/public/RecoverPassword';
import ResetPassword from '@/components/public/ResetPassword';
import ThankyouRegister from '@/components/public/registration/ThankYouRegister';
import ThankyouAgencyRegistration from '@/components/public/registration/ThankYouAgencyRegistration';
import VerifiedLogin from '@/components/public/VerifiedLogin';
import ThankyouContact from '@/components/public/ThankYouContact';
import PrivacyPolicy from '@/components/public/PrivacyPolicy';
import TermsConditions from '@/components/public/TermsConditions';
import WebsiteTerms from '@/components/public/WebsiteTerms';
import Sustainability from '@/components/public/SustainabilityPolicy';
import Brochures from '@/components/public/Brochures';
//import PromoLP1 from '@/components/public/PromoLP1';
//import PromoLP2 from '@/components/public/PromoLP2';
//import PromoLP3 from '@/components/public/PromoLP3';
//import PromoLP4 from '@/components/public/PromoLP4';
//import CoronaVirus from '@/components/public/CoronaVirus';
import VideoConnect from '@/components/public/Video';

const Blog = () => import("@/components/public/blog/Blog");
const BlogPost = () => import("@/components/public/blog/BlogPost");

import Destinations from '@/components/public/destinations/Destinations';
import NewZealandDestinations from '@/components/public/destinations/NewZealand';
import AustraliaDestinations from '@/components/public/destinations/Australia';

import DestinationAuckland from '@/components/public/destinations/north-island/DestinationAuckland';
import DestinationWellington from '@/components/public/destinations/north-island/DestinationWellington';
import DestinationHawkesBay from '@/components/public/destinations/north-island/DestinationHawkesBay';
import DestinationTairawhitiGisborne from '@/components/public/destinations/north-island/DestinationTairawhitiGisborne';
import DestinationTaranaki from '@/components/public/destinations/north-island/DestinationTaranaki';
import DestinationManawatu from '@/components/public/destinations/north-island/DestinationManawatu';
import DestinationWairarapa from '@/components/public/destinations/north-island/DestinationWairarapa';
import DestinationWhanganui from '@/components/public/destinations/north-island/DestinationWhanganui';
import DestinationBayOfPlenty from '@/components/public/destinations/north-island/DestinationBayOfPlenty';
import DestinationCoromandel from '@/components/public/destinations/north-island/DestinationCoromandel';
import DestinationNorthland from '@/components/public/destinations/north-island/DestinationNorthland';
import DestinationRotorua from '@/components/public/destinations/north-island/DestinationRotorua';
import DestinationRuapehu from '@/components/public/destinations/north-island/DestinationRuapehu';
import DestinationTaupo from '@/components/public/destinations/north-island/DestinationTaupo';
import DestinationWaikato from '@/components/public/destinations/north-island/DestinationWaikato';

import DestinationCentralOtago from '@/components/public/destinations/south-island/DestinationCentralOtago';
import DestinationQueenstown from '@/components/public/destinations/south-island/DestinationQueenstown';
import DestinationDunedin from '@/components/public/destinations/south-island/DestinationDunedin';
import DestinationChristchurchCanterbury from '@/components/public/destinations/south-island/DestinationChristchurchCanterbury';
import DestinationFiordland from '@/components/public/destinations/south-island/DestinationFiordland';
import DestinationNelsonTasman from '@/components/public/destinations/south-island/DestinationNelsonTasman';
import DestinationSouthland from '@/components/public/destinations/south-island/DestinationSouthland';
import DestinationWanaka from '@/components/public/destinations/south-island/DestinationWanaka';
import DestinationMarlborough from '@/components/public/destinations/south-island/DestinationMarlborough';
import DestinationWestCoast from '@/components/public/destinations/south-island/DestinationWestCoast';
import DestinationKaikoura from '@/components/public/destinations/south-island/DestinationKaikoura';
import DestinationHurunui from '@/components/public/destinations/south-island/DestinationHurunui';
import DestinationClutha from '@/components/public/destinations/south-island/DestinationClutha';
import DestinationTimaru from '@/components/public/destinations/south-island/DestinationTimaru';

import DestinationACT from '@/components/public/destinations/australia/DestinationACT';
import DestinationNSW from '@/components/public/destinations/australia/DestinationNSW';
import DestinationNT from '@/components/public/destinations/australia/DestinationNT';
import DestinationQLD from '@/components/public/destinations/australia/DestinationQLD';
import DestinationSA from '@/components/public/destinations/australia/DestinationSA';
import DestinationTAS from '@/components/public/destinations/australia/DestinationTAS';
import DestinationVIC from '@/components/public/destinations/australia/DestinationVIC';
import DestinationWA from '@/components/public/destinations/australia/DestinationWA';
import Deals2Easy from '@/components/public/Deals2Easy.vue';

export const publicRoutes = [
  {
    path: '/',
    component: Public,
    children: [
      {
        path: '',
        name: 'home',
        component: Home,
        meta: {
          title: 'ANZCRO | The Australian and New Zealand Holiday Experts',
          description:
            'ANZCRO - The Australian and New Zealand Holiday Experts. Send us an email or contact our reservations office on the telephone numbers provided.',
        },
      },
      {
        path: 'register/',
        name: 'register',
        component: Register,
      },
      {
        path: 'new-agency-registration/',
        name: 'agencyregistration',
        component: AgencyRegistration,
      },
      {
        path: 'agent-account-requested/',
        name: 'thankyouRegister',
        component: ThankyouRegister,
      },
      {
        path: 'agency-registration-requested/',
        name: 'thankyouAgencyRegistration',
        component: ThankyouAgencyRegistration,
      },
      {
        path: 'about/',
        name: 'about',
        component: About,
      },
      {
        path: 'contact/',
        name: 'contact',
        component: Contact,
        meta: {
          title: 'Contact Us - ANZCRO | The Australian and New Zealand Holiday Experts',
          description:
            'Contact Us - ANZCRO - The Australian and New Zealand Holiday Experts. Send us an email or contact our reservations office on the telephone numbers provided.',
        },
      },
      /*
      {
        path: 'suppliers/',
        name: 'suppliers',
        component: Suppliers,
      },
      */
      {
        path: 'brochures/',
        name: 'brochures',
        component: Brochures,
      },
      {
        path: 'group-travel/',
        name: 'groups',
        component: Groups,
      },
      {
        path: 'deals/',
        name: 'deals',
        component: Deals,
      },/*
      {
        path: 'deals/australian-independent-holidays/',
        name: 'dealsaustralianholidays',
        component: DealsAustralianHolidays,
      },
      {
        path: 'deals/holidays-at-home/',
        name: 'dealshalfpriceflights',
        component: DealsHalfPriceFlights,
      },*/
      {
        path: 'deals/new-zealand-coach-tours1/',
        name: 'dealscoach',
        component: DealsCoach,
      },
      {
        path: 'deals/new-zealand-coach-tours/',
        name: 'dealscoachtour',
        component: DealsCoachTours,
      },
      {
        path: 'deals/new-zealand-rail/',
        name: 'dealsrail',
        component: DealsRail,
      },
      {
        path: 'deals/gardens-new-zealand/',
        name: 'dealsgardens',
        component: DealsGardens,
      },
      {
        path: 'deals/rail-journeys/',
        name: 'dealsrailjourneys',
        component: DealsRail,
      },
      {
        path: 'deals/nz-city-breaks/',
        name: 'dealsnzcitybreaks',
        component: DealsCityBreaks,
      },
      {
        path: 'deals/new-zealand-book/',
        name: 'dealsnzbook',
        component: DealsNZBook,
      },
      {
        path: 'deals/new-zealand-highways/',
        name: 'dealsnzhighways',
        component: DealsNZHighways,
      },
      {
        path: 'deals/christchurch/',
        name: 'dealschristchurch',
        component: DealsChristchurch,
      },
      {
        path: 'deals/iconic-new-zealand/',
        name: 'dealsiconicnz',
        component: DealsIconicNZ,
      },
      {
        path: 'deals/hurunui/',
        name: 'dealshurunui',
        component: DealsHurunui,
      },/*
      {
        path: 'deals/waikato/',
        name: 'dealswaikato',
        component: DealsWaikato,
      },
      {
        path: 'deals/queenstown/',
        name: 'dealsqueenstown',
        component: DealsQueenstown,
      },*/
      {
        path: 'deals/all-time-nz/',
        name: 'dealsalltimenzholidays',
        component: DealsAllTime,
      },
      /*
      {
        path: 'deals/signature-australia/',
        name: 'dealssignatureaustralia',
        component: DealsSignatureAustralia,
      },
      */
      {
        path: 'deals/walking-adventures/',
        name: 'dealswalkingau',
        component: DealsWalkingAU,
      },
      {
        path: 'deals/australia-bucket-list/',
        name: 'dealsaustraliabucketlist',
        component: DealsAustraliaBucketList,
      },
      {
        path: 'deals/australia-nature-coast-drive/',
        name: 'dealsaustralianaturecoast',
        component: DealsAustraliaNatureCoast,
      },
      {
        path: 'deals/south-pacific/',
        name: 'dealspacific',
        component: DealsPacific,
      },
      {
        path: 'deals/lord-howe-norfolk-island/',
        name: 'dealslordhowenorfolk',
        component: DealsLordHoweNorfolk,
      },
      {
        path: 'deals/rotorua/',
        name: 'dealsrotorua',
        component: DealsRotorua,
      },
      {
        path: 'deals/bay-of-plenty/',
        name: 'dealsbayofplenty',
        component: DealsBayOfPlenty,
      },
      {
        path: 'deals/2-easy',
        name: 'deals2easy',
        component: Deals2Easy,
      },


      {
        path: 'login/',
        name: 'login',
        component: Login,
      },
      {
        path: 'terms-and-conditions/',
        name: 'termsConditions',
        component: TermsConditions,
      },
      {
        path: 'website-terms/',
        name: 'websiteTerms',
        component: WebsiteTerms,
      },
      {
        path: 'enquiry-received/',
        name: 'thankyouContact',
        component: ThankyouContact,
      },
      {
        path: 'verified-login/',
        name: 'verifiedLogin',
        component: VerifiedLogin,
      },
      {
        path: 'privacy-policy/',
        name: 'privacyPolicy',
        component: PrivacyPolicy,
      },
      {
        path: 'recover-password/',
        name: 'recoverPassword',
        component: RecoverPassword,
      },
      {
        path: 'reset-password/',
        name: 'resetPassword',
        component: ResetPassword,
      },
      {
        path: 'sustainability-policy/',
        name: 'sustainability',
        component: Sustainability,
      },
      /*
      {
        path: 'ski-deals/',
        name: 'promolp1',
        component: PromoLP1,
      },
      {
        path: 'west-coast-deals/',
        name: 'promolp2',
        component: PromoLP2,
      },{
        path: 'motorhome-deals/',
        name: 'promolp3',
        component: PromoLP3,
      },
      {
        path: 'air-new-zealand-deal/',
        name: 'promolp4',
        component: PromoLP4,
      },
      {
        path: 'covid19/',
        name: 'coronavirus',
        component: CoronaVirus,
      },
      {
        path: 'book-with-confidence/',
        name: 'bookwithconfidence',
        component: BookWithConfidence,
      },*/
      {
        path: 'the-insider/',
        name: 'blog',
        component: Blog,
      },
      {
        path: 'the-insider/category/:slug/',
        name: 'blogcategory',
        component: Blog,
      },
      {
        path: 'the-insider/:slug/',
        name: 'blogpost',
        component: BlogPost,
      },
      {
        path: 'connect-video/',
        name: 'videoconnect',
        component: VideoConnect,
      },
      {
        path: 'destinations/',
        name: 'destinations',
        component: Destinations,
      },
      {
        path: 'destinations/new-zealand/',
        name: 'nzdestinations',
        component: NewZealandDestinations,
      },
      {
        path: 'destinations/new-zealand/north-island/auckland/',
        name: 'destinationAuckland',
        component: DestinationAuckland,
      },
      {
        path: 'destinations/new-zealand/north-island/hawkes-bay/',
        name: 'destinationHawkesBay',
        component: DestinationHawkesBay,
      },
      {
        path: 'destinations/new-zealand/north-island/wellington/',
        name: 'destinationWellington',
        component: DestinationWellington,
      },
      {
        path: 'destinations/new-zealand/north-island/tairawhiti-gisborne/',
        name: 'destinationTairawhitiGisborne',
        component: DestinationTairawhitiGisborne,
      },
      {
        path: 'destinations/new-zealand/north-island/taranaki/',
        name: 'destinationTaranaki',
        component: DestinationTaranaki,
      },
      {
        path: 'destinations/new-zealand/north-island/manawatu/',
        name: 'destinationManawatu',
        component: DestinationManawatu,
      },
      {
        path: 'destinations/new-zealand/north-island/wairarapa/',
        name: 'destinationWairarapa',
        component: DestinationWairarapa,
      },
      {
        path: 'destinations/new-zealand/north-island/whanganui/',
        name: 'destinationWhanganui',
        component: DestinationWhanganui,
      },
      {
        path: 'destinations/new-zealand/north-island/bay-of-plenty/',
        name: 'destinationBayOfPlenty',
        component: DestinationBayOfPlenty,
      },
      {
        path: 'destinations/new-zealand/north-island/the-coromandel/',
        name: 'destinationCoromandel',
        component: DestinationCoromandel,
      },
      {
        path: 'destinations/new-zealand/north-island/northland/',
        name: 'destinationNorthland',
        component: DestinationNorthland,
      },
      {
        path: 'destinations/new-zealand/north-island/rotorua/',
        name: 'destinationRotorua',
        component: DestinationRotorua,
      },
      {
        path: 'destinations/new-zealand/north-island/ruapehu/',
        name: 'destinationRuapehu',
        component: DestinationRuapehu,
      },
      {
        path: 'destinations/new-zealand/north-island/taupo/',
        name: 'destinationTaupo',
        component: DestinationTaupo,
      },
      {
        path: 'destinations/new-zealand/north-island/waikato/',
        name: 'destinationWaikato',
        component: DestinationWaikato,
      },
      {
        path: 'destinations/new-zealand/south-island/central-otago/',
        name: 'destinationCentralOtago',
        component: DestinationCentralOtago,
      },
      {
        path: 'destinations/new-zealand/south-island/queenstown/',
        name: 'destinationQueenstown',
        component: DestinationQueenstown,
      },
      {
        path: 'destinations/new-zealand/south-island/christchurch/',
        name: 'destinationChristchurchCanterbury',
        component: DestinationChristchurchCanterbury,
      },
      {
        path: 'destinations/new-zealand/south-island/dunedin/',
        name: 'destinationDunedin',
        component: DestinationDunedin,
      },
      {
        path: 'destinations/new-zealand/south-island/fiordland/',
        name: 'destinationFiordland',
        component: DestinationFiordland,
      },
      {
        path: 'destinations/new-zealand/south-island/nelson-tasman/',
        name: 'destinationNelsonTasman',
        component: DestinationNelsonTasman,
      },
      {
        path: 'destinations/new-zealand/south-island/southland/',
        name: 'destinationSouthland',
        component: DestinationSouthland,
      },
      {
        path: 'destinations/new-zealand/south-island/wanaka/',
        name: 'destinationWanaka',
        component: DestinationWanaka,
      },
      {
        path: 'destinations/new-zealand/south-island/marlborough/',
        name: 'destinationMarlborough',
        component: DestinationMarlborough,
      },
      {
        path: 'destinations/new-zealand/south-island/west-coast/',
        name: 'destinationWestCoast',
        component: DestinationWestCoast,
      },
      {
        path: 'destinations/new-zealand/south-island/kaikoura/',
        name: 'destinationKaikoura',
        component: DestinationKaikoura,
      },
      {
        path: 'destinations/new-zealand/south-island/hurunui/',
        name: 'destinationHurunui',
        component: DestinationHurunui,
      },
      {
        path: 'destinations/new-zealand/south-island/clutha/',
        name: 'destinationClutha',
        component: DestinationClutha,
      },
      {
        path: 'destinations/new-zealand/south-island/timaru/',
        name: 'destinationTimaru',
        component: DestinationTimaru,
      },
      {
        path: 'destinations/australia/',
        name: 'audestinations',
        component: AustraliaDestinations,
        meta: {
          title: 'ANZCRO | The New Zealand Holiday Experts'
        }
      },
      {
        path: 'destinations/australia/australian-capital-territory/',
        name: 'destinationACT',
        component: DestinationACT,
        meta: {
          title: 'ANZCRO | The New Zealand Holiday Experts'
        }
      },
      {
        path: 'destinations/australia/new-south-wales/',
        name: 'destinationNSW',
        component: DestinationNSW,
        meta: {
          title: 'ANZCRO | The New Zealand Holiday Experts'
        }
      },
      {
        path: 'destinations/australia/northern-territory/',
        name: 'destinationNT',
        component: DestinationNT,
        meta: {
          title: 'ANZCRO | The New Zealand Holiday Experts'
        }
      },
      {
        path: 'destinations/australia/queensland/',
        name: 'destinationQLD',
        component: DestinationQLD,
        meta: {
          title: 'ANZCRO | The New Zealand Holiday Experts'
        }
      },
      {
        path: 'destinations/australia/south-australia/',
        name: 'destinationSA',
        component: DestinationSA,
        meta: {
          title: 'ANZCRO | The New Zealand Holiday Experts'
        }
      },
      {
        path: 'destinations/australia/victoria/',
        name: 'destinationVIC',
        component: DestinationVIC,
        meta: {
          title: 'ANZCRO | The New Zealand Holiday Experts'
        }
      },
      {
        path: 'destinations/australia/western-australia/',
        name: 'destinationWA',
        component: DestinationWA,
        meta: {
          title: 'ANZCRO | The New Zealand Holiday Experts'
        }
      },
      {
        path: 'destinations/australia/tasmania/',
        name: 'destinationTAS',
        component: DestinationTAS,
        meta: {
          title: 'ANZCRO | The New Zealand Holiday Experts'
        }
      }
    ],
  },
];
