<template>
    <v-container pa-0 fluid>
      <v-layout row>
        <v-flex>
          <v-card flat tile dark class="hero-home-slider">
            <v-carousel height="auto" class="elevation-0 static-hero" hide-controls>
              <v-carousel-item>
                <v-img
                  src="https://cdn.anzcro.com.au/wp-content/uploads/2024/09/hero-akl-rot-2easy_3840x1390-scaled.jpg"
                  gradient="to bottom, rgba(22,22,22,0.65), rgba(22,22,22,.25) 15%, rgba(22,22,22,0.15) 65%"
                  class="home-hero"
                >
                  <v-container fill-height>
                    <v-layout dark column fill-height justify-space-between pt-5>
                      <v-flex text-xs-center class="home-hero-cta">
                        <v-layout row align-center fill-height class="fill-height-fix-ios">
                          <v-flex>
                            <img src="https://cdn.anzcro.com.au/wp-content/uploads/2024/09/auckland-rotorua-2easy-stacked.png" alt="Choose Your Motorhome Adventure" class="campaign-title stacked" width="580" height="400" />
                          </v-flex>
                        </v-layout>
                      </v-flex>
                    </v-layout>
                  </v-container>
                  <div class="hero-caption hidden-xs-only">
                    <!--<small>Photo: THL</small>-->
                  </div>
                </v-img>
              </v-carousel-item>
            </v-carousel>
          </v-card>
        </v-flex>
      </v-layout>
      <div class="gradient-bar"></div>
  
      <v-layout row>
        <v-flex mb-5>
            <v-container grid-list-lg fill-height>
              <v-layout row wrap fill-height>
                <v-flex xs12 py-0 my-0>
                  <v-card tile class="pa-0 elevation-0 deals-content">
                    <v-card-text class="pa-5">
                      <v-layout row wrap>
                        <v-flex xs12 md10 py-0 my-0>
                            <span class="headline font-weight-medium kiwiGreen d-block mb-3">
                                Discover the best of New Zealand with a quick and effortless getaway.
                            </span>
                            <p>
                                Experience the magic of two iconic destinations—Auckland and Rotorua—in just five unforgettable days.
                            </p>
                            <p>
                                Enjoy a perfect balance of urban excitement and natural wonders. Start in Auckland, the vibrant city by the sea, and unwind in Rotorua, the heart of geothermal landscapes and rich Maori culture. It's never been easier to escape, explore, and recharge. Whether you want to reconnect with a loved one, create new memories as a family or catch up with some friends, you’ll find a 2 + 2 adventure that makes it 2 easy to go.
                            </p>
                            <p>
                                To celebrate the launch of this collection, ANZCRO, Tātaki Auckland Unlimited and RotoruaNZ are giving one lucky agent the chance to WIN.
                            </p>
                        </v-flex>
                        
                        <v-flex xs12 md2 py-0 my-0 text-xs-center>
                            <a target="_blank" href="https://cdn.anzcro.com.au/wp-content/uploads/2024/09/Incentive-Flyer-2-Easy.pdf">
                                <img src="https://cdn.anzcro.com.au/wp-content/uploads/2024/09/Incentive-Flyer-2-Easy-JPG-scaled.jpg" alt="2 Easy Incentive Flyer" class="campaign-sticker kirra-early-bird" width="124" height="174" />
                            </a>
                            <small class="kiwiGreen d-block mt-2" style="line-height:1.2;">
                                <a target="_blank" href="https://cdn.anzcro.com.au/wp-content/uploads/2024/09/Incentive-Flyer-2-Easy.pdf"><b>Learn How to Win</b></a>
                            </small>
                        </v-flex>
                       
                      </v-layout>
                    </v-card-text>
                  </v-card>
                </v-flex>
                <v-flex xs12 py-0 my-0 v-for="(section, index) in sections" :key="index">
                  <v-card tile dark class="pa-0 ma-0 elevation-0">
                    <v-card-text class="px-3 py-2">
                      <span
                        class="headline font-weight-medium d-block"
                      >{{section.title}}</span>
                    </v-card-text>
                  </v-card>
                  <v-card tile class="px-3 py-3 elevation-0">
                    <v-layout row wrap ma-0>
                      <v-flex xs12 pa-0 hidden-sm-and-down>
                        <v-layout row wrap my-0>
                          <v-flex xs12 md7 lg8>
                            <span class="caption grey--text">Package Deal</span>
                          </v-flex>
                          <v-flex xs12 md3 lg2 text-md-right>
                            <span class="caption grey--text">From Price (AUD)</span>
                          </v-flex>
                          <v-flex xs12 md2 lg2 text-md-center>
                            <span class="caption grey--text">Agent Resources</span>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                      <v-flex xs12 pa-0 v-for="(deal, dindex) in section.deals" :key="dindex">
                        <v-divider></v-divider>
                        <v-layout row wrap align-center my-3>
                          <v-flex xs12 md7 lg8>
                            <v-layout row align-center>
                              <v-flex shrink hidden-md-and-down>
                                <v-img :src="deal.thumbnail" class="deal-thumbnail"></v-img>
                              </v-flex>
                              <v-flex>
                                <span class="title d-block font-weight-medium mb-2">{{deal.name}}</span>
                                <span class="caption d-block mb-1 grey--text"><b>Dates:</b> {{deal.valid}}</span>
                                <span class="d-block package-data">
                                  <v-chip label color="#f5f5f5" disabled class="mb-0">
                                    <b>Start:</b>&nbsp;<span class="package-start-location">{{deal.location}}</span>
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                          <v-btn small icon v-on="on" class="mr-0" v-on:click="copyLocation"><v-icon size="14" :color="kiwiGreen">content_copy</v-icon></v-btn>
                                        </template>
                                        <span>Copy Start location</span>
                                    </v-tooltip>
                                  </v-chip>
                                  <v-chip label color="#f5f5f5" disabled class="mb-0">
                                    <b>Code:</b>&nbsp;<span class="package-code">{{deal.code}}</span>
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                          <v-btn small icon v-on="on" class="mr-0" v-on:click="copyCode"><v-icon size="14" :color="kiwiGreen">content_copy</v-icon></v-btn>
                                        </template>
                                        <span>Copy Package Code</span>
                                    </v-tooltip>
                                  </v-chip>
                                </span>
                              </v-flex>
                            </v-layout>
                          </v-flex>
                          <v-flex xs12 sm6 md3 lg2 text-md-right>
                            <span class="headline d-block font-weight-medium mb-2 kiwiGreen">${{parseInt(deal.price).toLocaleString()}}<sup>*</sup></span>
                            <span class="caption d-block grey--text">{{deal.complement}}</span>
                          </v-flex>
                          <v-flex xs12 sm6 md2 lg2 text-sm-right text-md-center>
                            <v-tooltip top v-if="deal.sm_tile">
                              <template v-slot:activator="{ on }">
                                <v-btn large v-on="on" icon class="card-with-border elevation-0 mr-1 px-0" color="white" :href="deal.sm_tile" target="_blank"><v-icon :color="kiwiGreen" size="22" class="">image</v-icon></v-btn>
                              </template>
                              <span>View Social Media Tile</span>
                            </v-tooltip>
                            <v-tooltip top v-if="deal.itinerary">
                              <template v-slot:activator="{ on }">
                                <v-btn large v-on="on" icon class="card-with-border elevation-0 mx-0 px-0" :color="kiwiGreen" :href="deal.itinerary" target="_blank"><v-icon color="white" size="22" class="">description</v-icon></v-btn>
                              </template>
                              <span>View Flyer</span>
                            </v-tooltip>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                    </v-layout>
                  </v-card>
                </v-flex>
              </v-layout>
            </v-container>
        </v-flex>
      </v-layout>
  
  
    <v-snackbar :color="kiwiGreen" v-model="copied" bottom class="mb-4" timeout="2500">{{copiedText}} <v-btn flat @click="copied = false">Close</v-btn></v-snackbar>
    </v-container>
  </template>
  <script>
  export default {
    data: () => ({
      copied: false,
      copiedText: '',
      sections : [
        {
          title : 'Deals',
          deals : [
            {
              thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2024/09/Catch-Up-136x136-1.png',
              name : '2 Easy 2 Catch Up',
              price : '1059',
              complement : 'per person (twin share)',
              code : 'ANZ242E2CU',
              location : 'Auckland (AKL)',
              valid: '01 Oct 2024 - 30 Jun 2025',
              itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2024/09/2-Easy-2-Catch-Up-AUD.pdf',
              sm_tile: 'https://cdn.anzcro.com.au/wp-content/uploads/2024/09/2-Easy-2-Catch-Up.jpg'
            },
            {
              thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2024/09/See-NZ-Icons-136x136-1.png',
              name : '2 Easy 2 See NZ Icons',
              price : '1179',
              complement : 'per person (twin share)',
              code : 'ANZ242E2SNZI',
              location : 'Auckland (AKL)',
              valid: '01 Oct 2024 - 30 Jun 2025',
              itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2024/09/2-Easy-2-See-NZ-Icons-AUD-v2.pdf',
              sm_tile: 'https://cdn.anzcro.com.au/wp-content/uploads/2024/09/2-Easy-2-See-NZ-Icons.jpg'
            },
            {
              thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2024/09/Recharge-136x136-1.png',
              name : '2 Easy 2 Recharge',
              price : '1219',
              complement : 'per person (twin share)',
              code : 'ANZ242E2R',
              location : 'Auckland (AKL)',
              valid: '01 Nov 2024 - 30 Jun 2025',
              itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2024/09/2-Easy-2-Recharge-AUD.pdf',
              sm_tile: 'https://cdn.anzcro.com.au/wp-content/uploads/2024/09/2-Easy-2-Recharge.jpg'
            },
            {
              thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2024/09/Be-Immersed-in-a-New-Culture-136x136-1.png',
              name : '2 Easy 2 Be Immersed in a New Culture',
              price : '1245',
              complement : 'per person (twin share)',
              code : 'ANZ242E2BINC',
              location : 'Auckland (AKL)',
              valid: '01 Oct 2024 - 30 Jun 2025',
              itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2024/09/2-Easy-2-Be-Immersed-in-a-New-Culture-AUD.pdf',
              sm_tile: 'https://cdn.anzcro.com.au/wp-content/uploads/2024/09/2-Easy-2-Be-Immersed-in-a-New-Culture.jpg'
            },
            {
              thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2024/09/Explore-136x136-1.png',
              name : '2 Easy 2 Explore',
              price : '1289',
              complement : 'per person (twin share)',
              code : 'ANZ242E2E',
              location : 'Auckland (AKL)',
              valid: '01 Oct 2024 - 30 Jun 2025',
              itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2024/09/2-Easy-2-Explore-AUD.pdf',
              sm_tile: 'https://cdn.anzcro.com.au/wp-content/uploads/2024/09/2-Easy-2-Explore.jpg'
            },
            {
              thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2024/09/Be-Immersed-in-Nature-136x136-1.png',
              name : '2 Easy 2 Be Immersed in Nature',
              price : '1509',
              complement : 'per person (twin share)',
              code : 'ANZ242E2BINAT',
              location : 'Auckland (AKL)',
              valid: '01 Oct 2024 - 30 Jun 2025',
              itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2024/09/2-Easy-2-Be-Immersed-in-Nature-AUD.pdf',
              sm_tile: 'https://cdn.anzcro.com.au/wp-content/uploads/2024/09/2-Easy-2-Be-Immersed-in-Nature.jpg'
            },
            {
              thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2024/09/Getaway-Together-136x136-1.png',
              name : '2 Easy 2 Getaway Together',
              price : '3859',
              complement : 'per family (2Ad+2Ch)',
              code : 'ANZ242E2GT',
              location : 'Auckland (AKL)',
              valid: '01 Oct 2024 - 30 Jun 2025',
              itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2024/09/2-Easy-2-Getaway-Together-AUD.pdf',
              sm_tile: 'https://cdn.anzcro.com.au/wp-content/uploads/2024/09/2-Easy-2-Getaway-Together.jpg'
            },
          ]
        },
      ]
    }),
    mounted: function() {
      const el = document.scrollingElement || document.documentElement;
      el.scrollTop = 0;
    },
    methods: {
      top() {
        document.documentElement.scrollTop = 0;
      },
      copyCode(event) {
        if(event){
          let copyCode = event.target.closest('.v-chip__content').querySelector('.package-code');
          window.getSelection().selectAllChildren(copyCode);
          document.execCommand("copy");
          window.getSelection().removeAllRanges();
          //alert(copyCode.innerHTML);
          this.copiedText = 'Package code copied to clipboard';
          this.copied = true;
        }
      },
      copyLocation(event) {
        if(event){
          let copyLocation = event.target.closest('.v-chip__content').querySelector('.package-start-location');
          window.getSelection().selectAllChildren(copyLocation);
          document.execCommand("copy");
          window.getSelection().removeAllRanges();
          //alert(copyLocation.innerHTML);
          this.copiedText = 'Package start location copied to clipboard';
          this.copied = true;
        }
      }
    },
  };
  </script>
  
  <style>
  .gradient-bar{
    width: 100%;
    height: 9px;
    background: -webkit-gradient(linear,left top,right top,from(#65b32e),color-stop(#d2d2d2),to(#30302f));
    background: linear-gradient(90deg,#65b32e,#d2d2d2,#30302f);
  }
  
  .deals-content{
    margin-top: -60px;
  }
  
  .package-data .v-chip{
    color:#9e9e9e;
    margin-left: 0;
  }
  
  .package-data .v-chip .v-chip__content{
    padding-right: 6px;
  }
  
  .deal-thumbnail{
    width: 88px;
    height: 88px;
  }
  </style>
  